import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_23 from '../../images/leistungen/img23.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Akupunktur | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet professionelle Akupunktur mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        {/* <SimpleModernHeadline h1={true}>Akupunktur</SimpleModernHeadline>
        <SimpleModernHeadline h2={true}>Harmonie durch Nadeln</SimpleModernHeadline> */}
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Akupunktur"} subtitle={"Harmonie durch Nadeln"} frontImage={img_leistung_23}></HeadlineWithImage>
          </Col>
        </Row>
        Die Akupunktur ist eine der ältesten Therapieform der Welt und gehört zur traditionellen chinesischen Medizin.
        <br/>
        <br/>
        Durch Einstiche an bestimmten Punkten auf Leitungsbahnen (Meridianen), die über den Körper ziehen, wird Einfluss auf die Lebensenergie des Körpers (Qi) genommen. Laut chinesischer Medizin entsteht Krankheit durch gestörten Energiefluss. Durch die Akupunktur soll der aus dem Takt geratene Energiestrom behutsam reguliert werden. 
        <br/>
        <br/>
        Nach modernen Kenntnissen werden durch die Akupunktur bestimmte Zonen im Gehirn und Rückenmark stimuliert, die die Schmerzverarbeitung beeinflussen und zur Ausschüttung von stimmungsaufhellenden Substanzen („Glückshormone“) führt.
        <br/>
        <br/>
        Die Wirkung der Akupunktur ist in Abhängigkeit der Punktauswahl
        <ul>
          <li>schmerzlindernd,</li>
          <li>abschwellend,</li>
          <li>psychisch ausgleichend,</li>
          <li>immunmodulierend und/ oder</li>
          <li>durchblutungsfördernd.</li>
        </ul>
        Die Anwendungsgebiete der Akupunktur sind vielfältig und reichen von chronischen Schmerzen bei verschleißbedingten Erkrankungen des Bewegungsapparates (Arthrose) bis hin zur Prophylaxe bei Migräne und Raucherentwöhnung.
        <br/>
        <br/>
        Ich biete Ihnen neben der klassischen Körperakupunktur auch die Therapieoption, über spezielle Punkte am Kopf oder Ohr die Wirkung zu erzeugen/zu erzielen:
        <ul>
          <li>Schädelakupunktur nach Yamamoto</li>
          <li>Auriculo Therapie nach Nogier</li>
        </ul>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
